import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  MenuEventArgs
} from "@syncfusion/ej2-react-splitbuttons";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import { getFormatedDate } from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  triggerRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  convertUTCDateToLocalTimezone,
  getDate,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareRecipientPermissionConstants, CareServiceContractItemPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  MomentFormats,
  RAFButtonConstant,
} from "../../../constants/Common/Constants";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import CareServiceContractItemList from "../CareServiceContractItem/CareServiceContractItemList";
import CreateCareServiceContractItem from "../CareServiceContractItem/CreateCareServiceContractItem";
import ManageCareServiceContract from "./ManageCareServiceContract";

interface IProps {
  careRecipientUID: string;
  careServiceContractRow: any;
  onSave: () => void;
  careServiceContractEntity: EntityRow;
  careServiceContractItemEntity: EntityRow;
}

interface IState {
  isCollapsed: boolean;
  showUpdateServiceContract: boolean;
  showCreateCareServiceContractItem: boolean;
  randomValueToTriggerSave: number;
}

function CareServiceContractCardContent({
  careServiceContractRow,
  ...props
}: PropsWithChildren<IProps>) {
  let deleteDialog: any;
  const careServiceContractModule =
    CareEsioEntity.CareServiceContract.EntityName;

  const careServiceContractItemModule =
    CareEsioEntity.CareServiceContractItem.EntityName;

  const objectContext = React.useContext(RAFObjectContext);
  const rafObject = isNotNullAndUndefined(objectContext)
    ? objectContext.rafObject
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isCollapsed: true,
      showUpdateServiceContract: false,
      showCreateCareServiceContractItem: false,
      randomValueToTriggerSave: 0,
    }
  );

  //UpdateServiceContract start
  const editClick = () => {
    setState({ showUpdateServiceContract: true });
  };

  const updateServiceContractContent = () => {
    if (state.showUpdateServiceContract) {
      return (
        <ManageCareServiceContract
          objectUID={careServiceContractRow.UID}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdateServiceContractDialog.bind(this)}
          onDelete={refreshOnUpdate}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnUpdate = () => {
    setState({ showUpdateServiceContract: false });
    setTimeout(() => {
      if (props.onSave) {
        props.onSave();
      }
    }, 10);
  };

  const closeUpdateServiceContractDialog = () => {
    setState({ showUpdateServiceContract: false });
  };
  //UpdateServiceContract end

  function careServiceContractSectionCustomTitle() {
    let dateValue;
    let dayValue;
    let monthValue;
    if (isNotNullAndUndefined(careServiceContractRow.StartDate)) {
      dateValue = getFormatedDate(careServiceContractRow.StartDate, "DD");
      monthValue = getFormatedDate(careServiceContractRow.StartDate, "MMM");

      const objDate: Date = convertUTCDateToLocalTimezone(
        careServiceContractRow.StartDate
      );
      dayValue = getDate(objDate, "ddd");
      if (
        dayValue !== "Today" &&
        dayValue !== "Yesterday" &&
        dayValue !== "Tomorrow"
      ) {
        dayValue = getFormatedDate(objDate, "dddd");
      }
    }

    return (
      <div
        id={"care_service_contract" + careServiceContractRow.UID}
        className="d-flex align-items-center justify-content-between w-100 actionSection"
      >
        <RAFDetailsValueWithSeparator
          outerClassName="h-auto"
          rowClassName="g-2"
        >
          <RAFDetailFieldCustom
            value={
              isNotNullAndUndefined(careServiceContractRow.StartDate)
                ? careServiceContractRow.StartDate
                : "Not set"
            }
            title="Start Date:"
            field="StartDate"
            fieldFormat={{
              type: RAFDataType.Date,
              format: MomentFormats.DATE,
            }}
            labelDivClassName="col-auto"
            valueDivClassName="col-auto"
            labelClassName="body_3 text-uppercase"
            valueClassName="body_3_dark_bold"
            rowClassName="gx-2 align-items-center"
          />
          <RAFDetailFieldCustom
            value={
              isNotNullAndUndefined(careServiceContractRow.EndDate)
                ? careServiceContractRow.EndDate
                : "Not set"
            }
            title="End Date:"
            field="EndDate"
            fieldFormat={{
              type: RAFDataType.Date,
              format: MomentFormats.DATE,
            }}
            labelDivClassName="col-auto"
            valueDivClassName="col-auto"
            labelClassName="body_3 text-uppercase"
            valueClassName="body_3_dark_bold"
            rowClassName="gx-2 align-items-center"
          />
        </RAFDetailsValueWithSeparator>
      </div>
    );
  }

  const onMoreDropdownClicked = (args: MenuEventArgs) => {
    if (
      isNotNullAndUndefined(args) &&
      isNotNullAndUndefined(args.item) &&
      isNotNullAndUndefined(args.item.id)
    ) {
      if (args.item.id === "AddContractItem") {
        onClickAddContractItem();
      } else if (args.item.id === "Edit") {
        editClick();
      }
    }
  };

  //CareServiceContractItem start
  const onClickAddContractItem = () => {
    setState({ showCreateCareServiceContractItem: true });
  };

  const refreshOnCreateCareServiceContractItem = () => {
    const eventKey = `${RAFEventName.ReloadRelatedItemsContent}_${careServiceContractItemModule}_${props.careRecipientUID}`;
    triggerRAFEvent(eventKey, {
      careRecipientUID: `${props.careRecipientUID}`,
    });
    setState({ showCreateCareServiceContractItem: false });
    setTimeout(() => {
      if (props.onSave) {
        props.onSave();
      }
    }, 10);
  };

  useEffect(() => {
    if (state.randomValueToTriggerSave !== 0) {
      if (props.onSave) {
        props.onSave();
      }
    }
  }, [state.randomValueToTriggerSave]); // Add the specific property as a dependency

  const createCareServiceContractItemContent = () => {
    if (state.showCreateCareServiceContractItem) {
      let initialValues = {};
      if (isNotNullAndUndefined(rafObject)) {
        initialValues["CareRecipientUID"] = rafObject.UID;
        initialValues["CareRecipient"] =
          rafObject.first_name + " " + rafObject.last_name;
      }
      initialValues["ServiceContractUID"] = careServiceContractRow.UID;
      initialValues["ServiceContract"] =
        careServiceContractRow.ServiceContractId;
      initialValues["IsActive"] = true;

      return (
        <CreateCareServiceContractItem
          initialValues={initialValues}
          moduleName={careServiceContractItemModule}
          onSave={refreshOnCreateCareServiceContractItem.bind(this)}
          onClose={closeCreateCareServiceContractItemDialog.bind(this)}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeCreateCareServiceContractItemDialog = () => {
    setState({ showCreateCareServiceContractItem: false });
  };
  //CareServiceContractItem end

  function careServiceContractSectionCustomButton() {
    return (
      <div className="row align-items-center gx-2">
        <RAFPermissionRender
          permissionName={
            CareRecipientPermissionConstants.CareRecipientManageServiceAgreements
          }
        >
          <div className="col-auto d-none d-md-block">
            <RAFButtonComponent
              className="btn_brand_secondary"
              action={RAFButtonConstant.Edit}
              iconCss={`${RAFButtonConstant.Edit.IconCss}`}
              onClick={() => editClick()}
            />
          </div>
          <div className="col-auto d-md-none">
            <RAFButtonComponent
              className="btn_brand_secondary"
              action={RAFButtonConstant.Edit}
              iconCss={`${RAFButtonConstant.Edit.IconCss}`}
              onClick={() => editClick()}
              iconBtn
            />
          </div>
          {state.showUpdateServiceContract && (
            <DialogComponent
              header={`Update ${CareEsioEntity.CareServiceContract.DisplayName}`}
              showCloseIcon
              visible={state.showUpdateServiceContract}
              cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
              id={`dlg_update_${careServiceContractModule}`}
              content={updateServiceContractContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={closeUpdateServiceContractDialog.bind(this)}
              zIndex={1200}
              open={PreventFocusOnDialogOpen}
            ></DialogComponent>
          )}
        </RAFPermissionRender>

      </div>
    );
  }

  if (isNotNullAndUndefined(careServiceContractRow)) {
    return (
      <div className="care_service_contract_col_card">
        <RAFCollapseWithPlusIcon
          toggleArrowIcon="Arrow"
          customTitle={careServiceContractSectionCustomTitle()}
          customButton={careServiceContractSectionCustomButton()}
          title={careServiceContractRow.title}
          collapsePanelHeaderClass={"p-0"}
          allowFullRowClick
          collapsePanelContentClassname="p-0 pt-3"
          showSeparator={false}
          collapsePanelRowClass="g-0 gy-3"
        >
          <div className="w-100">
            <div className="row gx-2 gy-2">
              <div className="col-sm-6 col-xl-3 col-12">
                <CustomCardWidget
                  cardContentClassName="py-2"
                >
                  <RAFDetailFieldCustom
                    value={careServiceContractRow.AvailableAmount}
                    rowClassName="gy-1"
                    title="Available"
                    field="AvailableAmount"
                    labelClassName="body_3_light"
                    valueClassName="subtitle_1 ecllipseFirstLine"
                    fieldFormat={{ type: RAFDataType.Currency }}
                  />
                </CustomCardWidget>
              </div>

              <div className="col-sm-6 col-xl-3 col-12">
                <CustomCardWidget
                  cardContentClassName="py-2"
                >
                  <RAFDetailFieldCustom
                    value={careServiceContractRow.AllocatedAmount}
                    rowClassName="gy-1"
                    title="Allocated"
                    field="AllocatedAmount"
                    labelClassName="body_3_light"
                    valueClassName="subtitle_1 ecllipseFirstLine"
                    fieldFormat={{ type: RAFDataType.Currency }}
                  />
                </CustomCardWidget>
              </div>

              <div className="col-sm-6 col-xl-3 col-12">
                <CustomCardWidget
                  cardContentClassName="py-2"
                >
                  <RAFDetailFieldCustom
                    value={careServiceContractRow.UtilisedAmount}
                    rowClassName="gy-1"
                    title="Utilized"
                    field="UtilisedAmount"
                    labelClassName="body_3_light"
                    valueClassName="subtitle_1 ecllipseFirstLine"
                    fieldFormat={{ type: RAFDataType.Currency }}
                  />
                </CustomCardWidget>
              </div>

              <div className="col-sm-6 col-xl-3 col-12">
                <CustomCardWidget
                  cardContentClassName="py-2"
                >
                  <RAFDetailFieldCustom
                    value={careServiceContractRow.BalanceAmount}
                    rowClassName="gy-1"
                    title="Balance"
                    field="BalanceAmount"
                    labelClassName="body_3_light"
                    valueClassName="subtitle_1 ecllipseFirstLine"
                    fieldFormat={{ type: RAFDataType.Currency }}
                  />
                </CustomCardWidget>
              </div>
            </div>
          </div>
          <div className="custom__card__separator custom__card__separator__light"></div>
          <div className="row gy-2">
            <div className="col-12">
              <div className="row">
                <div className="col">
                  <div className="body_2 medium mb-2 mx-3">
                    <span>
                      {CareEsioEntity.CareServiceContractItem.CollectionName}
                    </span>
                  </div>
                </div>
                <RAFPermissionRender
                  permissionName={
                    CareServiceContractItemPermissionConstants.CareServiceContractItemAdd
                  }
                >
                  <div className="col-auto d-none d-lg-block">
                    <RAFButtonComponent
                      isPrimary
                      action={RAFButtonConstant.Add}
                      btnContent={`Add Item`}
                      onClick={() => onClickAddContractItem()}
                      className="custom-button-sm"
                    />
                  </div>
                  <div className="col-auto d-lg-none d-block">
                    <RAFButtonComponent
                      isPrimary
                      action={RAFButtonConstant.Add}
                      btnContent={`Add Item`}
                      onClick={() => onClickAddContractItem()}
                      className="custom-button-sm"
                      iconBtn
                    />
                  </div>
                  {state.showCreateCareServiceContractItem && (
                    <DialogComponent
                      header={`Add ${CareEsioEntity.CareServiceContractItem.DisplayName}`}
                      showCloseIcon
                      visible={state.showCreateCareServiceContractItem}
                      cssClass="rightDialog createEditForm rightDialog-md full-height form-center-dialog dlg-new-style"
                      //cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
                      id={`dlg_create_${careServiceContractItemModule}`}
                      content={createCareServiceContractItemContent.bind(this)}
                      isModal
                      target="body"
                      closeOnEscape={false}
                      close={closeCreateCareServiceContractItemDialog.bind(this)}
                      open={PreventFocusOnDialogOpen}
                      zIndex={1200}
                    ></DialogComponent>
                  )}
                </RAFPermissionRender>
                {/* <div className="col-auto" style={{ width: '10rem' }}>
                <div className="body_2 medium mb-2 mx-3">
                  <span>
                    Total
                  </span>
                </div>
              </div>
              <div className="col-auto" style={{ width: '10rem' }}>
                <div className="body_2 medium mb-2 mx-3">
                  <span>
                    Balance
                  </span>
                </div>
              </div> */}
              </div>
            </div>

            <div className="col-12">
              <CareServiceContractItemList
                careRecipientUID={props.careRecipientUID}
                serviceContractUID={careServiceContractRow.UID}
                serviceContractId={careServiceContractRow.ServiceContractId}
                careServiceContractItemEntity={
                  props.careServiceContractItemEntity
                }
              />
            </div>
          </div>
        </RAFCollapseWithPlusIcon>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(CareServiceContractCardContent);
