import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm, { Condition } from "../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFNumber from "../../../RAFComponents/Inputs/RAFNumber";
import RAFRadioButtonList from "../../../RAFComponents/Inputs/RAFRadioButtonList";
import {
  getFormValue,
  setFormValue,
} from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  getRelatedRecords,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  IDialogProps,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined
} from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  ContentType,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { getAllPriceList } from "../PriceList/PriceListHelper";
import {
  RetrievePriceListItem,
  getPriceListItemByPriceListItemId,
} from "../PriceList/PriceListItem/PriceListItemHelper";
import { PriceListItemRow } from "../PriceList/PriceListItem/PriceListItemRow";
import { getPricelistItemCustomFilter } from "./CareServiceContractItemHelper";

interface IProps {
  moduleName: string;
  onDelete?: () => void;
  initialValues?: any;
}

interface IState {
  isLoading: boolean;
  initialValues: any;
  allpriceList: any[];
  priceListItems: PriceListItemRow[];
  selectedPriceListItems: PriceListItemRow[];
}

function CreateCareServiceContractItem({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const careServiceContractItemModule =
    CareEsioEntity.CareServiceContractItem.EntityName;

  let rafForm: FormRenderProps | null;
  const outerDivId = `raf_dlg_Outer_Div_${props.moduleName}`;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      initialValues: null,
      allpriceList: null,
      priceListItems: null,
      selectedPriceListItems: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      let initialObject = {};
      if (isNotNullAndUndefined(props.initialValues)) {
        initialObject = props.initialValues;
      }

      const allpriceList = await getAllPriceList(RAFActionStatus.Active);

      if (isNotEmptyArray(allpriceList)) {
        const defaultPriceList = allpriceList.find((x) => x.IsDefault === true);

        if (isNotNullAndUndefined(defaultPriceList)) {
          initialObject["Pricelist"] = defaultPriceList.Name;
          initialObject["PricelistUID"] = defaultPriceList.UID;
        } else {
          initialObject["Pricelist"] = allpriceList[0].Name;
          initialObject["PricelistUID"] = allpriceList[0].UID;
        }

        initialObject["BudgetType"] = "Service";
        initialObject["IsAgreementAmount"] = true;

        setState({
          initialValues: initialObject,
          isLoading: false,
          allpriceList,
        });
      } else {
        setState({
          initialValues: initialObject,
          isLoading: false,
        });
      }
    }
  };

  const checkIsItemExist = async (
    serviceContractUID: string,
    pricelistItemUID: string,
    uid: string
  ) => {
    return new Promise<boolean>(async (resolve, reject) => {
      if (
        isNotNullAndUndefined(pricelistItemUID) &&
        isNotNullAndUndefined(serviceContractUID)
      ) {
        const relatedItems = await getRelatedRecords(
          careServiceContractItemModule,
          null,
          "ServiceContractUID",
          serviceContractUID,
          null,
          careServiceContractItemModule,
          null,
          null,
          null,
          null,
          null,
          `related_${careServiceContractItemModule}`
        );
        if (isNotEmptyArray(relatedItems)) {
          const isExist = relatedItems.some(
            (item) =>
              item.PricelistItemUID === pricelistItemUID && item.UID !== uid
          );
          resolve(isExist);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  };

  const onSubmitObject = async (value) => {
    let formValue = R.clone(value);

    let progressDiv = showProgress(`#${outerDivId}`);
    let selectedContractItems = value["ServiceContractItems"];
    let budgetLevel = value["BudgetType"];
    let saveListRequest = [];

    delete formValue.ServiceContractItems;
    delete formValue.BudgetType;

    const isItemExist = await checkIsItemExist(
      formValue.ServiceContractUID,
      formValue.PricelistItemUID,
      formValue.UID
    );

    if (isItemExist) {
      hideProgress(progressDiv);
      showWarningToast("Item already exist with same service name");
      return;
    }

    let saveRequestData = getSaveRequest(formValue, formValue.UID);
    saveRequestData.EntityName = careServiceContractItemModule;

    repositoryActions
      .postDataAndGetResponse(
        "ServiceContractItem/Save",
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          if (isNotEmptyArray(selectedContractItems)) {
            selectedContractItems.forEach((item) => {
              delete item.BudgetType;
              item.ParentUID = response.data.EntityId;

              item.IsAgreementAmount =
                budgetLevel === "Line item" ? true : false;
              item.TotalAmount =
                budgetLevel === "Line item" ? item.TotalAmount : null;
              let entity = { Entity: item, EntityId: item.UID };
              saveListRequest.push(entity);
            });

            repositoryActions
              .postDataAndGetResponse(
                "ServiceContractItem/SaveList",
                saveListRequest,
                null,
                ContentType.applicationJson
              )
              .then((response) => {
                hideProgress(progressDiv);
                if (isNotNullAndUndefined(props.onSave)) {
                  props.onSave();
                }
              });
          } else {
            hideProgress(progressDiv);
            if (isNotNullAndUndefined(props.onSave)) {
              props.onSave();
            }
          }
        } else {
          showWarningToast("Sorry something went wrong !");
          if (isNotNullAndUndefined(props.onSave)) {
            props.onSave();
          }
        }
      })
      .catch((error) => error);
  };

  const onChangePriceListItem = async (label, value) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const priceListItem: PriceListItemRow = isNotNullAndUndefined(value)
      ? await RetrievePriceListItem(value)
      : null;

    if (isNotNullAndUndefined(priceListItem)) {
      const priceListItems = await getPriceListItemByPriceListItemId(
        priceListItem.UID
      );
      if (isNotEmptyArray(priceListItems)) {
        // Loop over priceListItems and create ServiceContractItems
        const serviceContractItems = priceListItems.map((item) => ({
          // Fill in the fields with the values from item
          Name: item.DisplayName ?? item.Name,
          Rate: item.Rate,
          RateType: item.RateType,
          Pricelist: item.Pricelist,
          PricelistUID: item.PricelistUID,
          PricelistItemUID: item.UID,
          Service: item.Service,
          ServiceUID: item.ServiceUID,
          ServiceName: item.Name,
          ServiceCode: item.Code,
          PricelistItem: item.DisplayName ?? item.Name,
          ...props.initialValues,
        }));

        setFormValue(rafForm, "TotalAmount", null);
        setFormValue(rafForm, "ServiceContractItems", serviceContractItems);
        setState({ priceListItems });
      } else {
        setFormValue(rafForm, "TotalAmount", null);
        setFormValue(rafForm, "ServiceContractItems", null);
        setState({ priceListItems: null });
      }

      hideProgress(progressDiv);
      setFormValue(
        rafForm,
        "Name",
        isNotNullAndUndefined(priceListItem.DisplayName)
          ? priceListItem.DisplayName
          : priceListItem.Name
      );
      setFormValue(rafForm, "Rate", priceListItem.Rate);
      setFormValue(rafForm, "RateType", priceListItem.RateType);
      //setFormValue(rafForm, "Pricelist", priceListItem.Pricelist);
      //setFormValue(rafForm, "PricelistUID", priceListItem.PricelistUID);
      setFormValue(rafForm, "PricelistItemUID", priceListItem.UID);
      //setFormValue(rafForm, "PricelistItem", priceListItem.DisplayName);
      setFormValue(rafForm, "Service", priceListItem.Service);
      setFormValue(rafForm, "ServiceUID", priceListItem.ServiceUID);
      setFormValue(rafForm, "ServiceName", priceListItem.Name);
      setFormValue(rafForm, "ServiceCode", priceListItem.Code);
    } else {
      hideProgress(progressDiv);
      setFormValue(rafForm, "Name", null);
      setFormValue(rafForm, "Rate", null);
      setFormValue(rafForm, "RateType", null);
      //setFormValue(rafForm, "Pricelist", null);
      //setFormValue(rafForm, "PricelistUID", null);
      setFormValue(rafForm, "PricelistItemUID", null);
      //setFormValue(rafForm, "PricelistItem", null);
      setFormValue(rafForm, "Service", null);
      setFormValue(rafForm, "ServiceUID", null);
      setFormValue(rafForm, "ServiceName", null);
      setFormValue(rafForm, "ServiceCode", null);
      setState({ priceListItems: null });
    }
  };

  const onTotalAmountChanged = (value, index) => {
    let lineItems = getFormValue(rafForm, "ServiceContractItems");
    lineItems[index].TotalAmount = value;

    // Calculate the total amount of all line items
    const totalAmount = lineItems.reduce(
      (total, item) => total + (item.TotalAmount || 0),
      0
    );

    // Update the service contract total amount
    setFormValue(rafForm, "ServiceContractItems", lineItems);
    setFormValue(rafForm, "TotalAmount", totalAmount);
  };

  const getPriceListItemContent = (showEmptyState: boolean) => {
    if (isNotEmptyArray(state.priceListItems)) {
      return (
        <div className="e-card mb-3">
          <div className="e-card-content p-0">
            <RAFCollapse
              title={"Price List Item"}
              headerRowClassName="with-height"
              contentCardClassName="relatedListOuterDiv p-2 p-md-3"
              removeContentCardPadding
              showCustomButtonOnHover
              headerBadge={
                <div className="secondary-text">
                  {" "}
                  <span className="e-badge e-badge-grey-border e-badge-notification e-badge-circle e-badge-md ms-2 position-static">
                    {state.priceListItems.length}
                  </span>
                </div>
              }
              IsCollapsed={false}
            >
              <FieldArray name="ServiceContractItems">
                {({ fields }) => {
                  return (
                    <div className="overflow-auto customScrollBar pt-3 px-2">
                      <table className="card-table">
                        <thead>
                          <tr className="header">
                            <th className="full-width">
                              <span className="subtitle_2 ecllipseFirstLine">
                                Item
                              </span>
                            </th>
                            <th className="full-width">
                              <span className="subtitle_2 ecllipseFirstLine">
                                Total Amount
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map((name, index) => (
                            <tr key={index} className="pointer">
                              <td className="full-width">
                                <div className="row g-0 gy-2">
                                  <div className="col-12">
                                    <div className="subtitle_1">
                                      {isNotNullAndUndefined(fields.value) &&
                                        isNotNullAndUndefined(
                                          fields.value[index]
                                        ) ? (
                                        <span>
                                          {fields.value[index]["Name"]}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="full-width">
                                <Condition when="BudgetType" is={"Service"}>
                                  <RAFNumber
                                    field={`${name}.TotalAmount`}
                                    showLabel={false}
                                    label="Total Amount"
                                    decimalsPoints={2}
                                    disabled={true}
                                  />
                                </Condition>
                                <Condition when="BudgetType" is={"Line item"}>
                                  <RAFNumber
                                    field={`${name}.TotalAmount`}
                                    showLabel={false}
                                    label="Total Amount"
                                    decimalsPoints={2}
                                    onChanged={(value) =>
                                      onTotalAmountChanged(value, index)
                                    }
                                    required
                                  />
                                </Condition>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <Condition when="BudgetType" is={"Line item"}>
                          <tfoot>
                            <tr style={{ border: "none" }}>
                              <td className="full-width">Total Amount</td>
                              <td className="full-width">
                                <RAFNumber
                                  field={`TotalAmount`}
                                  label="Total Amount"
                                  showLabel={false}
                                  decimalsPoints={2}
                                  disabled={true}
                                />
                              </td>
                            </tr>
                          </tfoot>
                        </Condition>
                      </table>
                    </div>
                  );
                }}
              </FieldArray>
            </RAFCollapse>
          </div>
        </div>
      );
    } else if (showEmptyState) {
      return null;
    }
  };

  const closeDialog = () => {
    if (isNotNullAndUndefined(props.onClose)) {
      props.onClose();
    }
  };

  const getServiceNameLookUpContent = () => {
    return (
      <Field name={"PricelistUID"}>
        {({ input }) => {
          const pricelistUID = input.value;
          const relatedFilter = getPricelistItemCustomFilter(pricelistUID);
          return (
            <RAFLookUpMUI
              key={pricelistUID}
              label="Service Name"
              field="PricelistItem"
              placeholder="Select Service"
              showLabel
              url="PriceListItem/LookUp"
              //createform={RAFCreate}
              moduleName={CareEsioEntity.CarePriceListItem.EntityName}
              // SearchCreateOptionMode={"Footer"}
              onChanged={(label, value) => onChangePriceListItem(label, value)}
              SearchCreateOptionMode="Footer"
              // formGroupClassName="inline-Editable"
              // labelClassName="col-3"
              // inputFieldClassName="col-9"
              {...(isNotNullAndUndefined(relatedFilter)
                ? { customFilter: relatedFilter }
                : {})}
              required
            />
          );
        }}
      </Field>
    );
  };

  const onChangePriceList = async (label, value) => {
    setFormValue(rafForm, "PricelistItem", null);
    setFormValue(rafForm, "PricelistItemUID", null);
    setFormValue(rafForm, "Pricelist", label);
    setFormValue(rafForm, "ServiceContractItems", null);
    setFormValue(rafForm, "TotalAmount", null);
    setState({ priceListItems: null });
  };

  const onBudgetTypeChange = (value) => {
    if (value === "Service") {
      setFormValue(rafForm, "IsAgreementAmount", true);
      setFormValue(rafForm, "TotalAmount", null);
      let lineItems = getFormValue(rafForm, "ServiceContractItems");
      if (isNotEmptyArray(lineItems)) {
        lineItems.forEach((item) => {
          item.IsAgreementAmount = false;
          item.TotalAmount = null;
        });
        setFormValue(rafForm, "ServiceContractItems", lineItems);
      }
    } else {
      setFormValue(rafForm, "IsAgreementAmount", false);
      setFormValue(rafForm, "TotalAmount", null);
    }
  };
  if (props.isActive) {
    if (state.isLoading === false) {
      return (
        <div className="h-100">
          <RAFForm
            initialValues={state.initialValues}
            formRef={(g) => (rafForm = g)}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={(values) => onSubmitObject(values)}
            className="h-100"
          >
            <div className="e-dlg-content-outer">
              <div className="e-dlg-body-content">
                <div>
                  <RAFDropdownCC
                    label="Pricelist"
                    field="PricelistUID"
                    placeholder="Select Pricelist"
                    onChanged={(label, value) =>
                      onChangePriceList(label, value)
                    }
                    showLabel
                  >
                    {state.allpriceList &&
                      state.allpriceList.map((item) => {
                        return (
                          <RAFChoiceOption
                            label={item.Name}
                            value={item.UID}
                            key={item.UID}
                          />
                        );
                      })}
                  </RAFDropdownCC>
                </div>
                <div>
                  <RAFRadioButtonList
                    field={`BudgetType`}
                    label="Budget Level"
                    showLabel
                    validate={false}
                    uitype="customToggleButton"
                    hideRequiredMessage
                    //labelPosition="right"
                    //labelClassName="col-md-4"
                    //inputFieldClassName="col-auto"
                    onChanged={(value) => {
                      onBudgetTypeChange(value);
                    }}
                  >
                    <RAFChoiceOption label="Service" value={"Service"} />
                    <RAFChoiceOption label="Line item" value={"Line item"} />
                  </RAFRadioButtonList>
                </div>
                <div>{getServiceNameLookUpContent()}</div>
                <div>{getPriceListItemContent(false)}</div>
                {/* <Condition when="BudgetType" is={"Line item"}>
                  <RAFNumber
                    field={`TotalAmount`}
                    label="Total Amount"
                    decimalsPoints={2}
                    disabled={true}
                  />
                </Condition> */}
                <Condition when="BudgetType" is={"Service"}>
                  <RAFNumber
                    field={`TotalAmount`}
                    label="Total Amount"
                    decimalsPoints={2}
                    required
                  />
                </Condition>

                {/* <div>
                  <RAFEntityProvider moduleName={careServiceContractItemModule}>
                    <CreateContent
                      moduleName={careServiceContractItemModule}
                      progressDivId={`#${outerDivId}`}
                    />
                  </RAFEntityProvider>
                </div> */}
              </div>
              <div className="e-dlg-footerContent ">
                <div className="w-100">
                  <div className="row gx-2">
                    <div className="col-auto ms-auto">
                      <RAFButtonComponent
                        type="button"
                        isPrimary
                        action={RAFButtonConstant.Save}
                        onClick={() => rafForm && rafForm.form.submit()}
                        idString="CreateContent"
                        disabled={rafForm && rafForm.submitting}
                      />
                    </div>
                    <div className="col-auto">
                      <RAFButtonComponent
                        type="button"
                        action={RAFButtonConstant.Cancel}
                        onClick={closeDialog}
                        idString="CreateContent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  }
}

export default React.memo(CreateCareServiceContractItem);
