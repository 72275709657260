import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm, { Condition } from "../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFNumber from "../../../RAFComponents/Inputs/RAFNumber";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  RetrieveRecord,
  getRelatedRecords,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  IsNullOrWhiteSpace,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined
} from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  ContentType,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import { getAllPriceList } from "../PriceList/PriceListHelper";
import { getPricelistItemCustomFilter } from "./CareServiceContractItemHelper";

interface IProps {
  isActive?: boolean;
  moduleName?: string;
  objectUID?: string;
  onSave?: (entityId?: string, objectName?: string) => void;
  onClose?: () => void;
  onDelete?: () => void;
  initialValues?: any;
}

interface IState {
  isLoading: boolean;
  allpriceList: any[];
}

function UpdateCareServiceContractItem({
  ...props
}: PropsWithChildren<IProps>) {
  const careServiceContractItemModule =
    CareEsioEntity.CareServiceContractItem.EntityName;

  let rafForm: FormRenderProps | null;
  const outerDivId = `raf_dlg_Outer_Div_${props.moduleName}`;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      allpriceList: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      const allpriceList = await getAllPriceList(RAFActionStatus.Active);
      setState({
        isLoading: false,
        allpriceList,
      });
    }
  };

  const checkIsItemExist = async (
    serviceContractUID: string,
    pricelistItemUID: string,
    uid: string
  ) => {
    return new Promise<boolean>(async (resolve, reject) => {
      if (isNotNullAndUndefined(pricelistItemUID)) {
        const relatedItems = await getRelatedRecords(
          careServiceContractItemModule,
          null,
          "ServiceContractUID",
          serviceContractUID,
          null,
          careServiceContractItemModule,
          null,
          null,
          null,
          null,
          null,
          `related_${careServiceContractItemModule}`
        );
        if (isNotEmptyArray(relatedItems)) {
          const isExist = relatedItems.some(
            (item) =>
              item.PricelistItemUID === pricelistItemUID && item.UID !== uid
          );
          resolve(isExist);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  };

  const onSubmitObject = async (value) => {
    const formValue = { ...value };

    let saveRequestData = getSaveRequest(formValue, formValue.UID);
    //saveRequestData.EntityUID = objEntity.UID;
    let progressDiv = showProgress(`#${outerDivId}`);

    const isItemExist = await checkIsItemExist(
      formValue.ServiceContractUID,
      formValue.PricelistItemUID,
      formValue.UID
    );

    if (isItemExist) {
      hideProgress(progressDiv);
      showWarningToast("Item already exist with same service name");
      return;
    }

    //if care shift log have form post selectedFormEntityname , if not post "care_shift_log"
    saveRequestData.EntityName = careServiceContractItemModule;

    repositoryActions
      .postDataAndGetResponse(
        "ServiceContractItem/Save",
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          if (isNotNullAndUndefined(props.onSave)) {
            props.onSave();
          }
        } else {
          showWarningToast("Sorry something went wrong !");
          if (isNotNullAndUndefined(props.onSave)) {
            props.onSave();
          }
        }
      })
      .catch((error) => error);
  };

  const onChangePriceListItem = async (label, value) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const priceListItem = isNotNullAndUndefined(value)
      ? await RetrieveRecord(value, CareEsioEntity.CarePriceListItem.EntityName)
      : null;
    hideProgress(progressDiv);
    if (isNotNullAndUndefined(priceListItem)) {
      setFormValue(
        rafForm,
        "Name",
        isNotNullAndUndefined(priceListItem.DisplayName)
          ? priceListItem.DisplayName
          : priceListItem.Name
      );
      setFormValue(rafForm, "Rate", priceListItem.Rate);
      setFormValue(rafForm, "RateType", priceListItem.RateType);
      setFormValue(rafForm, "Pricelist", priceListItem.Pricelist);
      setFormValue(rafForm, "PricelistUID", priceListItem.PricelistUID);
      setFormValue(rafForm, "PricelistItemUID", priceListItem.UID);
      //setFormValue(rafForm, "PricelistItem", priceListItem.DisplayName);
      setFormValue(rafForm, "Service", priceListItem.Service);
      setFormValue(rafForm, "ServiceUID", priceListItem.ServiceUID);
      setFormValue(rafForm, "ServiceName", priceListItem.Name);
      setFormValue(rafForm, "ServiceCode", priceListItem.Code);
    } else {
      setFormValue(rafForm, "Name", null);
      setFormValue(rafForm, "Rate", null);
      setFormValue(rafForm, "RateType", null);
      //setFormValue(rafForm, "Pricelist", null);
      //setFormValue(rafForm, "PricelistUID", null);
      setFormValue(rafForm, "PricelistItemUID", null);
      //setFormValue(rafForm, "PricelistItem", null);
      setFormValue(rafForm, "Service", null);
      setFormValue(rafForm, "ServiceUID", null);
      setFormValue(rafForm, "ServiceName", null);
      setFormValue(rafForm, "ServiceCode", null);
    }
  };

  const closeDialog = () => {
    if (isNotNullAndUndefined(props.onClose)) {
      props.onClose();
    }
  };

  const onChangePriceList = (label) => {
    setFormValue(rafForm, "PricelistItem", null);
    setFormValue(rafForm, "PricelistItemUID", null);
    setFormValue(rafForm, "Pricelist", label);
  };

  const getServiceNameLookUpContent = () => {
    return (
      <Field name={"PricelistUID"}>
        {({ input }) => {
          const pricelistUID = input.value;
          const relatedFilter = getPricelistItemCustomFilter(pricelistUID);
          return (
            <RAFLookUpMUI
              key={pricelistUID}
              label="Service Name"
              field="PricelistItem"
              placeholder="Select Service"
              showLabel
              url="PriceListItem/LookUp"
              //createform={RAFCreate}
              moduleName={CareEsioEntity.CarePriceListItem.EntityName}
              // SearchCreateOptionMode={"Footer"}
              onChanged={(label, value) => onChangePriceListItem(label, value)}
              SearchCreateOptionMode="Footer"
              // formGroupClassName="inline-Editable"
              // labelClassName="col-3"
              // inputFieldClassName="col-9"
              {...(isNotNullAndUndefined(relatedFilter)
                ? { customFilter: relatedFilter }
                : {})}
              required
            />
          );
        }}
      </Field>
    );
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      return (
        <RAFEntityProvider moduleName={props.moduleName}>
          <RAFObjectContextProvider
            moduleName={props.moduleName}
            objectId={props.objectUID}
            progressDivId={`#dlg_update_${props.moduleName}`}
          >
            <RAFObjectContext.Consumer>
              {({ /*objectId, */ rafObject, error }) => {
                if (
                  isNotNullAndUndefined(rafObject) &&
                  !IsNullOrWhiteSpace(rafObject.UID)
                ) {
                  return (
                    <RAFForm
                      initialValues={rafObject}
                      formRef={(g) => (rafForm = g)}
                      layout={RAFLayout.TwoColumnLayout}
                      onSubmit={(values) => onSubmitObject(values)}
                      primaryKey={rafObject.UID}
                      className="h-100"
                    >
                      <div className="e-dlg-content-outer">
                        <div className="e-dlg-body-content">
                          <div>
                            <RAFDropdownCC
                              label="Pricelist"
                              field="PricelistUID"
                              placeholder="Select Pricelist"
                              onChanged={onChangePriceList}
                              showLabel
                            >
                              {state.allpriceList &&
                                state.allpriceList.map((item) => {
                                  return (
                                    <RAFChoiceOption
                                      label={item.Name}
                                      value={item.UID}
                                      key={item.UID}
                                    />
                                  );
                                })}
                            </RAFDropdownCC>
                          </div>
                          <div>{getServiceNameLookUpContent()}</div>
                          <Condition when="IsAgreementAmount" is={true}>
                            <RAFNumber
                              field={`TotalAmount`}
                              label="Total Amount"
                              decimalsPoints={2}
                              required
                            />
                          </Condition>
                          <Condition when="IsAgreementAmount" is={false}>
                            <RAFNumber
                              field={`TotalAmount`}
                              label="Total Amount"
                              decimalsPoints={2}
                              disabled
                            />
                          </Condition>
                          {/* <div>
                            <EditContent
                              moduleName={props.moduleName}
                              progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
                            />
                          </div> */}
                        </div>
                        <div className="e-dlg-footerContent">
                          <div className="w-100">
                            <div className="row gx-2">
                              <div className="col-auto">
                                <RAFButtonComponent
                                  action={RAFButtonConstant.Delete}
                                  onClick={() => props.onDelete()}
                                  idString="DeleteContent"
                                  className="e-danger e-outline"
                                />
                              </div>
                              <div className="col-auto ms-auto">
                                <RAFButtonComponent
                                  isPrimary
                                  action={RAFButtonConstant.Save}
                                  onClick={() =>
                                    rafForm && rafForm.form.submit()
                                  }
                                  idString="EditContent"
                                  disabled={rafForm && rafForm.submitting}
                                />
                              </div>
                              <div className="col-auto">
                                <RAFButtonComponent
                                  action={RAFButtonConstant.Cancel}
                                  onClick={closeDialog}
                                  idString="EditContent"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </RAFForm>
                  );
                } else {
                  if (error === Constants.RAFObject_NoContent) {
                    return (
                      <div className="container-fluid px-0">
                        <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="container-fluid px-0"
                        style={{ background: "transparent" }}
                      >
                        {/* <ACLoadingPanel loadingText="Loading..." /> */}
                      </div>
                    );
                  }
                }
              }}
            </RAFObjectContext.Consumer>
          </RAFObjectContextProvider>
        </RAFEntityProvider>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  }
}

export default React.memo(UpdateCareServiceContractItem);
