import { DialogUtility } from "@syncfusion/ej2-popups";
import React, {
    PropsWithChildren
} from "react";
import { FormRenderProps } from "react-final-form";

import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFStartDateEndDatePicker from "../../../RAFComponents/Inputs/RAFStartDateEndDatePicker";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, { RAFAttributesContext } from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { DeleteRecord, RAFActionMessage, getRelatedRecords, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { getDisplayNameByModuleName } from "../../../RAFComponents/helpers/RAFMenuHelper";
import { IDialogProps, IsNotNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined, isNullOrUndefined } from "../../../RAFComponents/helpers/utils";
import { RAFUIType } from "../../../RAFComponents/models/Common/RAFDataType";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACNumber from "../../../components/shared/ACFormFields/ACNumber";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import { CareRecipientPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { Constants, RAFButtonConstant, RAFLayout } from "../../../constants/Common/Constants";
import RAFEntityProvider, { RAFEntityContext } from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import { getInitialValueForForm } from "../../Common/RAFFieldHelper";

interface IProps {
    initialValues?: any;
    onDelete?: () => void;
    objectUID?: string;
}

function ManageCareServiceContract({ ...props }: PropsWithChildren<IProps & IDialogProps>) {

    let rafForm: FormRenderProps | null;

    const moduleName = CareEsioEntity.CareServiceContract.EntityName;

    let deleteDialog: any;

    const onSubmitObject = (
        values,
        saveObject?: (
            objectData?: any
        ) => Promise<{ objectUID?: string; objectName?: string; }>
    ) => {
        saveObject(values)
            .then((response) => {
                //hideProgress(progressDiv);
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.objectUID)
                ) {
                    showSuccessToast("Saved successfully");
                    if (props.onSave)
                        props.onSave(response.objectUID, response.objectName);
                } else {
                    showWarningToast(`Apologies, we're unable to save the ${getDisplayNameByModuleName(moduleName)} at the moment. Please try again later.`);
                }
            })
            .catch((error) => error);
    };

    //delete item start
    const onClickDeleteSection = (entity: EntityRow) => {
        deleteDialog = DialogUtility.confirm({
            animationSettings: { effect: "Fade" },
            cancelButton: { text: "No" },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: "Yes", click: deleteRecord.bind(this, entity) },
            title: `Delete ${getDisplayNameByModuleName(
                moduleName,
                entity.DisplayName
            )}`,
            position: { X: "center", Y: "center" },
            cssClass: `alert-dialog deleteDialog`,
        });
    };

    const isChildeItemExist = () => {
        return new Promise<Boolean>(async (resolve) => {
            const careServiceContractItemModule =
                CareEsioEntity.CareServiceContractItem.EntityName;

            const relatedItems = await getRelatedRecords(
                careServiceContractItemModule,
                null,
                'ServiceContractUID',
                props.objectUID,
                null,
                careServiceContractItemModule,
                null,
                null,
                null,
                1,
                0,
                `related_${careServiceContractItemModule}`
            );
            if (isNotEmptyArray(relatedItems)) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    };

    async function deleteRecord(entity: EntityRow) {
        let progressDiv = showProgress(".deleteDialog.e-dialog");
        //check is child item exist
        const isChildeExist = await isChildeItemExist();
        if (isChildeExist) {
            showWarningToast("Apologies, you cannot delete this contract as there are one or more associated contract items.");
            hideProgress(progressDiv);
            deleteDialog.hide();
            return;
        }
        const isDeleted = await DeleteRecord(props.objectUID, moduleName, entity.UID);

        hideProgress(progressDiv);
        deleteDialog.hide();

        if (isDeleted) {
            if (props.onDelete) {
                props.onDelete();
            } else if (props.onSave) {
                props.onSave();
            }
        } else {
            showWarningToast(RAFActionMessage.RecordNotDeleted);
        }
    }
    //delete item end

    const isValidAvailableAmount = (availableAmountValue, allFormValue) => {
        let hasError = false, error = "";
        if (IsNotNullOrWhiteSpace(allFormValue)) {
            const totalAmountFieldValue = allFormValue['TotalAmount'];
            if (isNotNullAndUndefined(availableAmountValue) && IsNotNullOrWhiteSpace(totalAmountFieldValue)) {
                if (availableAmountValue > totalAmountFieldValue) {
                    hasError = true;
                    error = "Available Amount should not be greater than Total Amount";
                }
            }
        }

        return (hasError ? error : undefined);
    };

    const onchangeTotalAmount = (value) => {
        //const availableAmount = getFormValue(rafForm, "AvailableAmount");
        setFormValue(rafForm, "AvailableAmount", value);
    };

    if (props.isActive) {
        return (
            <RAFEntityProvider moduleName={moduleName}>
                <RAFAttributeRelatedListProvider moduleName={moduleName}>
                    <RAFAttributesContext.Consumer>
                        {({ queryAttributes }) => {
                            return (
                                <RAFEntityContext.Consumer>
                                    {({ entity }) => {
                                        return (
                                            <RAFObjectContextProvider
                                                moduleName={moduleName}
                                                objectId={props.objectUID}
                                                progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                                            >
                                                <RAFObjectContext.Consumer>
                                                    {({ /*objectId, */ rafObject, error, saveObject }) => {
                                                        const initialValues =
                                                            isNullOrUndefined(props.objectUID) ? getInitialValueForForm(queryAttributes, props.initialValues) :
                                                                (isNotNullAndUndefined(rafObject) && isNotNullAndUndefined(rafObject.UID)) ? rafObject : null;

                                                        if (
                                                            isNotNullAndUndefined(initialValues)
                                                        ) {
                                                            return (
                                                                <RAFForm
                                                                    initialValues={initialValues}
                                                                    formRef={(g) => {
                                                                        return (rafForm = g);
                                                                    }}
                                                                    layout={RAFLayout.TwoColumnLayout}
                                                                    onSubmit={(value) => onSubmitObject(value, saveObject)}
                                                                    primaryKey={props.objectUID}
                                                                    className="h-100"
                                                                >
                                                                    <div className="e-dlg-content-outer">
                                                                        <div className="e-dlg-body-content">
                                                                            <div className="row gx-2 gy-4">
                                                                                <RAFStartDateEndDatePicker
                                                                                    field="StartDate"
                                                                                    endDateField="EndDate"
                                                                                    label="Start Date"
                                                                                    showLabel
                                                                                    startDateUItype={RAFUIType.DateOnly}
                                                                                    endDateUItype={RAFUIType.DateOnly}
                                                                                    updateEndDateOnStartDateChange={false}
                                                                                    formGroupClassName="mb-0"
                                                                                    hideDuration
                                                                                    required
                                                                                />
                                                                                <ACDropdown
                                                                                    field={"Type"}
                                                                                    label="Type"
                                                                                    moduleName={moduleName}
                                                                                    allowAdd={false}
                                                                                    required
                                                                                />
                                                                                <ACTextBox
                                                                                    field={"FundingSource"}
                                                                                    label="Funding Source"
                                                                                    required
                                                                                />
                                                                                <ACNumber
                                                                                    field={"TotalAmount"}
                                                                                    label="Total Amount"
                                                                                    decimalsPoints={2}
                                                                                    onChanged={onchangeTotalAmount}
                                                                                    required
                                                                                />
                                                                                <ACNumber
                                                                                    field={"AvailableAmount"}
                                                                                    label="Available Amount"
                                                                                    decimalsPoints={2}
                                                                                    required
                                                                                    validators={[isValidAvailableAmount]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="e-dlg-footerContent">
                                                                            <div className="w-100">
                                                                                <div className="row gx-2">
                                                                                    {isNotNullAndUndefined(props.objectUID) && (
                                                                                        <RAFPermissionRender
                                                                                            permissionName={
                                                                                                CareRecipientPermissionConstants.CareRecipientDeleteServiceAgreements
                                                                                            }
                                                                                        >
                                                                                            <div className="col-auto">
                                                                                                <RAFButtonComponent
                                                                                                    action={RAFButtonConstant.Delete}
                                                                                                    onClick={() => onClickDeleteSection(entity)}
                                                                                                    idString="DeleteContent"
                                                                                                    className="e-danger e-outline"
                                                                                                />
                                                                                            </div>
                                                                                        </RAFPermissionRender>
                                                                                    )}
                                                                                    <div className="col-auto ms-auto">
                                                                                        <RAFPermissionRender
                                                                                            permissionName={
                                                                                                CareRecipientPermissionConstants.CareRecipientManageServiceAgreements
                                                                                            }
                                                                                        >
                                                                                            <RAFButtonComponent
                                                                                                isPrimary
                                                                                                action={RAFButtonConstant.Save}
                                                                                                onClick={() =>
                                                                                                    rafForm && rafForm.form.submit()
                                                                                                }
                                                                                                idString="EditContent"
                                                                                                disabled={rafForm && rafForm.submitting}
                                                                                            />
                                                                                        </RAFPermissionRender>
                                                                                    </div>
                                                                                    <div className="col-auto">
                                                                                        <RAFButtonComponent
                                                                                            action={RAFButtonConstant.Cancel}
                                                                                            onClick={props.onClose}
                                                                                            idString="EditContent"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </RAFForm>
                                                            );
                                                        } else {
                                                            if (error === Constants.RAFObject_NoContent) {
                                                                return (
                                                                    <div className="container-fluid px-0">
                                                                        <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div
                                                                        className="container-fluid px-0"
                                                                        style={{ background: "transparent" }}
                                                                    >
                                                                        {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                    }}
                                                </RAFObjectContext.Consumer>
                                            </RAFObjectContextProvider>
                                        );
                                    }}
                                </RAFEntityContext.Consumer>
                            );
                        }}
                    </RAFAttributesContext.Consumer>
                </RAFAttributeRelatedListProvider>
            </RAFEntityProvider>
        );
    } else {
        return (
            <div></div>
        );
    }
};


export default React.memo(ManageCareServiceContract);
