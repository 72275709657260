import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import RAFPermissionRender, {
  hasPermission,
} from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { CareServiceContractItemPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant
} from "../../../constants/Common/Constants";
import { getRelatedRecordByServiceContractId } from "./CareServiceContractItemHelper";
import { ServiceContractItemRow } from "./ServiceContractItem";

interface IProps {
  serviceContractUID: string;
  serviceContractId: string;
  rowClick: (rowData: any) => void;
}

interface IState {
  allServiceContractItems?: ServiceContractItemRow[];
  parentServiceContractItems?: ServiceContractItemRow[];
}

function CareServiceContractItemListContent({
  ...props
}: PropsWithChildren<IProps>) {
  useEffect(() => {
    refresh();
  }, [props.serviceContractUID, props.serviceContractId]);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      allServiceContractItems: null,
    }
  );

  const careServiceContractItemModule =
    CareEsioEntity.CareServiceContractItem.EntityName;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;
  const hasPermissionToEdit = hasPermission(
    permissionValue,
    CareServiceContractItemPermissionConstants.CareServiceContractItemUpdate
  );

  const rowClick = (rowData: any) => {
    if (hasPermissionToEdit) {
      if (props.rowClick) props.rowClick(rowData);
    }
  };

  const refresh = async () => {
    //get service transactions fro the shift
    const allServiceContractItems = await getRelatedRecordByServiceContractId(
      props.serviceContractUID
    );

    let parentServiceContractItems = isNotEmptyArray(allServiceContractItems)
      ? allServiceContractItems.filter((x) => isNullOrUndefined(x.ParentUID))
      : [];

    setState({ allServiceContractItems, parentServiceContractItems });
  };

  const getCustomTitle = (item: ServiceContractItemRow) => {
    return (
      <div className="w-100 px-3 py-2">
        <div className="row gx-0 gy-1">
          <div className="col-12">
            <div className="mb-1">
              <div className="row gx-2 align-items-center">
                <div className="col">
                  <div className="subtitle_2 ecllipseFirstLine">
                    <span>{item.Name}</span>
                  </div>
                </div>
                <div className="col-auto">
                  <RAFPermissionRender
                    permissionName={
                      CareServiceContractItemPermissionConstants.CareServiceContractItemAdd
                    }
                  >
                    <RAFButtonComponent
                      className="btn_brand_secondary outline e-round"
                      action={RAFButtonConstant.Edit}
                      iconCss={`${RAFButtonConstant.Edit.IconCss}`}
                      onClick={() => rowClick(item)}
                      iconBtn
                    />
                  </RAFPermissionRender>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="custom__card__separator custom__card__separator__light"></div>
          </div>
          <div className="col-12">
            <div className="row gx-0 gy-0 align-items-center flex-column">
              <div className="col-12">
                <div className="w-100 pt-2">
                  <div className="row gx-2 gy-2">
                    <div className="col-sm-6 col-xl-3 col-12">
                      <RAFDetailFieldCustom
                        value={item.TotalAmount}
                        rowClassName="gy-1"
                        title="Total Amount"
                        field="TotalAmount"
                        labelClassName="body_3_light"
                        valueClassName="subtitle_3"
                        fieldFormat={{
                          type: RAFDataType.Currency,
                        }}
                      />
                    </div>

                    <div className="col-sm-6 col-xl-3 col-12">
                      <RAFDetailFieldCustom
                        value={item.AllocatedAmount}
                        rowClassName="gy-1"
                        title="Allocated"
                        field="AllocatedAmount"
                        labelClassName="body_3_light"
                        valueClassName="subtitle_3"
                        fieldFormat={{
                          type: RAFDataType.Currency,
                        }}
                      />
                    </div>

                    <div className="col-sm-6 col-xl-3 col-12">
                      <RAFDetailFieldCustom
                        value={item.UtilisedAmount}
                        rowClassName="gy-1"
                        title="Utilized"
                        field="UtilisedAmount"
                        labelClassName="body_3_light"
                        valueClassName="subtitle_3"
                        fieldFormat={{
                          type: RAFDataType.Currency,
                        }}
                      />
                    </div>

                    <div className="col-sm-6 col-xl-3 col-12">
                      <RAFDetailFieldCustom
                        value={item.BalanceAmount}
                        rowClassName="gy-1"
                        title="Balance"
                        field="BalanceAmount"
                        labelClassName="body_3_light"
                        valueClassName="subtitle_3"
                        fieldFormat={{
                          type: RAFDataType.Currency,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="row gy-2 gx-0 ">
        {isNotEmptyArray(state.parentServiceContractItems) ? (
          state.parentServiceContractItems.map((parentServiceContract: any) => {
            const { allServiceContractItems } = state;
            const childServiceContractItems: ServiceContractItemRow[] =
              allServiceContractItems.filter((x) => x.ParentUID === parentServiceContract.UID);

            let editButton = (
              <div className="row align-items-center gx-2">
                <RAFPermissionRender
                  permissionName={
                    CareServiceContractItemPermissionConstants.CareServiceContractItemAdd
                  }
                >
                  <RAFButtonComponent
                    className="btn_brand_secondary"
                    action={RAFButtonConstant.Edit}
                    iconCss={`${RAFButtonConstant.Edit.IconCss}`}
                    onClick={() => rowClick(parentServiceContract)}
                  />
                </RAFPermissionRender>
              </div>
            );

            return (
              <>
                <CustomCardWidget
                  headerTemplate={getCustomTitle(parentServiceContract)}
                  cardContentClassName="p-3 overflow-auto customScrollBar"
                  cardClassName="surface_neutral_base overflow-hidden"
                  cardHeaderClassName="shadow-sm"
                >
                  <>
                    <table className="card-table">
                      <thead>
                        <tr className="header">
                          <th className="full-width">
                            <span className="subtitle_2 ecllipseFirstLine">
                              Service Name
                            </span>
                          </th>
                          <th>
                            <span className="subtitle_2 ecllipseFirstLine">
                              Total Amount
                            </span>
                          </th>
                          <th>
                            <span className="subtitle_2 ecllipseFirstLine">
                              Allocated Amount
                            </span>
                          </th>
                          <th>
                            <span className="subtitle_2 ecllipseFirstLine">
                              Utilized Amount
                            </span>
                          </th>
                          <th>
                            <span className="subtitle_2 ecllipseFirstLine">
                              Balance Amount
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {childServiceContractItems &&
                          childServiceContractItems.map((item: any) => {
                            const pricelistItemTitle = item.PricelistItem;
                            const parentServiceContractName = parentServiceContract.Name;
                            const serviceCode = parentServiceContract.ServiceCode;
                            const trimmedPricelistItemTitleText = pricelistItemTitle.replace(parentServiceContractName, '').trim();

                            const displayPricelistItemTitleValue = IsNotNullOrWhiteSpace(trimmedPricelistItemTitleText) ?
                              (trimmedPricelistItemTitleText[0] === '-' ? trimmedPricelistItemTitleText.substring(1).trim() : trimmedPricelistItemTitleText) : pricelistItemTitle;

                            const serviceCodePricelistItemValue = IsNotNullOrWhiteSpace(serviceCode) ? `${serviceCode} - ${displayPricelistItemTitleValue}` : displayPricelistItemTitleValue;

                            return (
                              <tr
                                key={item.UID}
                                onClick={() => rowClick(item)}
                                className="pointer surface_neutral_light"
                              >
                                <td className="full-width">
                                  <span className="subtitle_2 ecllipseFirstLine regular">
                                    {serviceCodePricelistItemValue}
                                  </span>
                                </td>
                                <td>
                                  <span className="body_2 ecllipseFirstLine">
                                    <RAFDetailFieldCustom
                                      value={item.TotalAmount}
                                      rowClassName="gy-1"
                                      title="Total Amount"
                                      field="TotalAmount"
                                      labelClassName="body_3_light"
                                      valueClassName="subtitle_3"
                                      fieldFormat={{
                                        type: RAFDataType.Currency,
                                      }}
                                      showLabel={false}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="body_2 ecllipseFirstLine">
                                    <RAFDetailFieldCustom
                                      value={item.AllocatedAmount}
                                      rowClassName="gy-1"
                                      title="Allocated Amount"
                                      field="AllocatedAmount"
                                      labelClassName="body_3_light"
                                      valueClassName="subtitle_3"
                                      fieldFormat={{
                                        type: RAFDataType.Currency,
                                      }}
                                      showLabel={false}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="body_2 ecllipseFirstLine">
                                    <RAFDetailFieldCustom
                                      value={item.UtilisedAmount}
                                      rowClassName="gy-1"
                                      title="Utilized Amount"
                                      field="UtilisedAmount"
                                      labelClassName="body_3_light"
                                      valueClassName="subtitle_3"
                                      fieldFormat={{
                                        type: RAFDataType.Currency,
                                      }}
                                      showLabel={false}
                                    />
                                  </span>
                                </td>
                                <td>
                                  <span className="body_2 ecllipseFirstLine">
                                    <RAFDetailFieldCustom
                                      value={item.BalanceAmount}
                                      rowClassName="gy-1"
                                      title="Balance Amount"
                                      field="BalanceAmount"
                                      labelClassName="body_3_light"
                                      valueClassName="subtitle_3"
                                      fieldFormat={{
                                        type: RAFDataType.Currency,
                                      }}
                                      showLabel={false}
                                    />
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {/* {childServiceContractItems &&
                      childServiceContractItems.map((childItem) => {
                        return (
                          <div
                            key={childItem.UID}
                            className="col-12 pointer mb-2 mt-2"
                            onClick={() => rowClick(childItem)}
                          >
                            <CustomCardWidget>
                              <div className="subtitle_2 ecllipseFirstLine">
                                <span>{childItem.PricelistItem}</span>
                              </div>
                              <div className="custom__card__separator custom__card__separator__light my-2"></div>
                              <div className="row gx-3">
                                <div className="col">
                                  <RAFDetailFieldCustom
                                    value={childItem.TotalAmount}
                                    rowClassName="gy-1"
                                    title="Total Amount"
                                    field="TotalAmount"
                                    labelClassName="body_3_light"
                                    valueClassName="subtitle_3"
                                    fieldFormat={{
                                      type: RAFDataType.Currency,
                                    }}
                                  />
                                </div>
                                <div className="col">
                                  <RAFDetailFieldCustom
                                    value={childItem.AllocatedAmount}
                                    rowClassName="gy-1"
                                    title="Allocated Amount"
                                    field="AllocatedAmount"
                                    labelClassName="body_3_light"
                                    valueClassName="subtitle_3"
                                    fieldFormat={{
                                      type: RAFDataType.Currency,
                                    }}
                                  />
                                </div>
                                <div className="col">
                                  <RAFDetailFieldCustom
                                    value={childItem.UtilisedAmount}
                                    rowClassName="gy-1"
                                    title="Utilized Amount"
                                    field="UtilisedAmount"
                                    labelClassName="body_3_light"
                                    valueClassName="subtitle_3"
                                    fieldFormat={{
                                      type: RAFDataType.Currency,
                                    }}
                                  />
                                </div>
                                <div className="col">
                                  <RAFDetailFieldCustom
                                    value={childItem.BalanceAmount}
                                    rowClassName="gy-1"
                                    title="Balance Amount"
                                    field="BalanceAmount"
                                    labelClassName="body_3_light"
                                    valueClassName="subtitle_3"
                                    fieldFormat={{
                                      type: RAFDataType.Currency,
                                    }}
                                  />
                                </div>
                              </div>
                            </CustomCardWidget>
                          </div>
                        );
                      })} */}
                  </>
                </CustomCardWidget>
                {/* <div className="e-card mb-3">
                  <div className="e-card-content p-0">
                    <RAFCollapse
                      customTitle={getCustomTitle(item)}
                      //title={item.DisplayName}
                      layout={RAFLayout.OneColumnLayout}
                      headerRowClassName="with-height"
                      contentCardClassName="relatedListOuterDiv p-2 p-md-3"
                      removeContentCardPadding
                      //showCustomButtonOnHover
                      IsCollapsed={false}
                      // customButton={editButton}
                      
                    >
                      <>
                        <div className="row gx-0 gy-0 align-items-center flex-column">
                          <div className="col-12">
                            <div className="w-100 pt-2">
                              <div className="row gx-2 gy-2">
                                <div className="col-sm-6 col-xl-3 col-12">
                                  <CustomCardWidget>
                                    <RAFDetailFieldCustom
                                      value={item.TotalAmount}
                                      rowClassName="gy-1"
                                      title="Total Amount"
                                      field="TotalAmount"
                                      labelClassName="body_3_light"
                                      valueClassName="subtitle_3"
                                      fieldFormat={{
                                        type: RAFDataType.Currency,
                                      }}
                                    />
                                  </CustomCardWidget>
                                </div>

                                <div className="col-sm-6 col-xl-3 col-12">
                                  <CustomCardWidget>
                                    <RAFDetailFieldCustom
                                      value={item.AllocatedAmount}
                                      rowClassName="gy-1"
                                      title="Allocated"
                                      field="AllocatedAmount"
                                      labelClassName="body_3_light"
                                      valueClassName="subtitle_3"
                                      fieldFormat={{
                                        type: RAFDataType.Currency,
                                      }}
                                    />
                                  </CustomCardWidget>
                                </div>

                                <div className="col-sm-6 col-xl-3 col-12">
                                  <CustomCardWidget>
                                    <RAFDetailFieldCustom
                                      value={item.UtilisedAmount}
                                      rowClassName="gy-1"
                                      title="Utilized"
                                      field="UtilisedAmount"
                                      labelClassName="body_3_light"
                                      valueClassName="subtitle_3"
                                      fieldFormat={{
                                        type: RAFDataType.Currency,
                                      }}
                                    />
                                  </CustomCardWidget>
                                </div>

                                <div className="col-sm-6 col-xl-3 col-12">
                                  <CustomCardWidget>
                                    <RAFDetailFieldCustom
                                      value={item.BalanceAmount}
                                      rowClassName="gy-1"
                                      title="Balance"
                                      field="BalanceAmount"
                                      labelClassName="body_3_light"
                                      valueClassName="subtitle_3"
                                      fieldFormat={{
                                        type: RAFDataType.Currency,
                                      }}
                                    />
                                  </CustomCardWidget>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {childServiceContractItems &&
                          childServiceContractItems.map((childItem) => {
                            return (
                              <div
                                key={childItem.UID}
                                className="col-12 pointer mb-2 mt-2"
                                onClick={() => rowClick(childItem)}
                              >
                                <CustomCardWidget>
                                  <div className="subtitle_2 ecllipseFirstLine">
                                    <span>{childItem.PricelistItem}</span>
                                  </div>
                                  <div className="custom__card__separator custom__card__separator__light my-2"></div>
                                  <div className="row gx-3">
                                    <div className="col">
                                      <RAFDetailFieldCustom
                                        value={childItem.TotalAmount}
                                        rowClassName="gy-1"
                                        title="Total Amount"
                                        field="TotalAmount"
                                        labelClassName="body_3_light"
                                        valueClassName="subtitle_3"
                                        fieldFormat={{
                                          type: RAFDataType.Currency,
                                        }}
                                      />
                                    </div>
                                    <div className="col">
                                      <RAFDetailFieldCustom
                                        value={childItem.AllocatedAmount}
                                        rowClassName="gy-1"
                                        title="Allocated Amount"
                                        field="AllocatedAmount"
                                        labelClassName="body_3_light"
                                        valueClassName="subtitle_3"
                                        fieldFormat={{
                                          type: RAFDataType.Currency,
                                        }}
                                      />
                                    </div>
                                    <div className="col">
                                      <RAFDetailFieldCustom
                                        value={childItem.UtilisedAmount}
                                        rowClassName="gy-1"
                                        title="Utilized Amount"
                                        field="UtilisedAmount"
                                        labelClassName="body_3_light"
                                        valueClassName="subtitle_3"
                                        fieldFormat={{
                                          type: RAFDataType.Currency,
                                        }}
                                      />
                                    </div>
                                    <div className="col">
                                      <RAFDetailFieldCustom
                                        value={childItem.BalanceAmount}
                                        rowClassName="gy-1"
                                        title="Balance Amount"
                                        field="BalanceAmount"
                                        labelClassName="body_3_light"
                                        valueClassName="subtitle_3"
                                        fieldFormat={{
                                          type: RAFDataType.Currency,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </CustomCardWidget>
                              </div>
                            );
                          })}
                      </>
                    </RAFCollapse>
                  </div>
                </div> */}
              </>
            );
          })
        ) : (
          <div className="col-12">
            <CustomCardWidget>
              <RAFEmptyState
                title={`No ${CareEsioEntity.CareServiceContractItem.CollectionName} to display`}
                body={`Looks like you haven't added any ${CareEsioEntity.CareServiceContractItem.CollectionName} yet`}
                displayStyle="TextOnly"
              ></RAFEmptyState>
            </CustomCardWidget>
          </div>
        )}
      </div>
      {/* <div className="e-card mb-3">
        <div className="e-card-content p-0">
          <table className="card-table">
            <thead>
              <tr className="header">
                <th className="full-width">
                  <span className="subtitle_2 ecllipseFirstLine">
                    Service Name
                  </span>
                </th>
                <th>
                  <span className="subtitle_2 ecllipseFirstLine">
                    Total Amount
                  </span>
                </th>
                <th>
                  <span className="subtitle_2 ecllipseFirstLine">
                    Allocated Amount
                  </span>
                </th>
                <th>
                  <span className="subtitle_2 ecllipseFirstLine">
                    Utilized Amount
                  </span>
                </th>
                <th>
                  <span className="subtitle_2 ecllipseFirstLine">
                    Balance Amount
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {state.allServiceContractItems &&
                state.allServiceContractItems.map((item: any) => {
                  return (
                    <tr
                      key={item.UID}
                      onClick={() => rowClick(item)}
                      className="pointer"
                    >
                      <td className="full-width">
                        <span className="subtitle_2 ecllipseFirstLine regular">
                          {item.Name}
                        </span>
                      </td>
                      <td>
                        <span className="body_2 ecllipseFirstLine">
                          {item.TotalAmount}
                        </span>
                      </td>
                      <td>
                        <span className="body_2 ecllipseFirstLine">
                          {item.AllocatedAmount}
                        </span>
                      </td>
                      <td>
                        <span className="body_2 ecllipseFirstLine">
                          {item.UtilisedAmount}
                        </span>
                      </td>
                      <td>
                        <span className="body_2 ecllipseFirstLine">
                          {item.BalanceAmount}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div> */}
      {/* <RAFFieldStateProvider
        moduleName={careServiceContractItemModule}
        viewName={`related_${careServiceContractItemModule}`}
      >
        <RAFGridTemplates>
          <RAFGrid4
            gridId={`grid3_${careServiceContractItemModule}_mainList_${props.serviceContractUID}`}
            url={`${Constants.baseAPIUrl}DataList/RelatedList`}
            moduleName={careServiceContractItemModule}
            additionalParams={[
              {
                key: "RelatedField",
                value: "ServiceContractUID",
              },
              {
                key: "RelatedFieldValue",
                value: props.serviceContractUID,
              },
              {
                key: "EntityName",
                value: careServiceContractItemModule,
              },
            ]}
            isRemote
            rowClick={rowClick}
            editClick={rowClick}
            showEditColumn={false}
            allowEditing={hasPermissionToEdit}
            allowSelection={false}
            allowFiltering={false}
            emptyStateProps={{
              body: `No data available at the moment!`,
              iconClass: `fa fa-entity fa-${careServiceContractItemModule}`,
            }}
            //additionalFilter={additionalFilter}
            sortBy={{
              field: "TaskDate",
              order: "ascending",
            }}
            isDynamic
            disableFirstColumnTemplate
            gridLines="None"

            cssClass="remove_grid_border"
          >
            <RAFGridColumn
              field="Name"
              headerText="Service Name"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={""}
            />
            {/* <RAFGridColumn
              field="Rate"
              headerText="Rate"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={""}
            />

            <RAFGridColumn
              field="TotalHours"
              headerText="Total Hours"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={""}
            />
            <RAFGridColumn
              field="UtilisedHours"
              headerText="Utilised Hours"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={""}
            />
            <RAFGridColumn
              field="BalanceHours"
              headerText="Balance Hours"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={""}
            /> */}
      {/*<RAFGridColumn
              field="TotalAmount"
              headerText="Total Amount"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={""}
            />
            <RAFGridColumn
              field="AllocatedAmount"
              headerText="Allocated Amount"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={""}
            />
            <RAFGridColumn
              field="UtilisedAmount"
              headerText="Utilized Amount"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={""}
            />
            <RAFGridColumn
              field="BalanceAmount"
              headerText="Balance Amount"
              width={"150px"}
              minWidth={"150px"}
              maxWidth={""}
            />
          </RAFGrid4>
        </RAFGridTemplates>
      </RAFFieldStateProvider> */}
    </div>
  );
}

export default React.memo(CareServiceContractItemListContent);
