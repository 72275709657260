import * as R from "ramda";
import React, { Reducer, useContext, useReducer, useRef } from "react";
import { FormRenderProps } from "react-final-form";
import { ITheme, Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import RAFForm from "../../../../RAFComponents/Inputs/RAFForm";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  RAFActionMessage,
  SaveDataList,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { customTheme } from "../../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import { EvaluateScore } from "../../../../RAFMaster/helpers/RMutils";
import {
  BrowserIsDevice,
  BrowserWidth,
  Constants,
  RAFButtonConstant,
  RAFLayout,
} from "../../../../constants/Common/Constants";

import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../../Common/Providers/RAFEntityProvider";
import {
  CareEsioEntity,
  CareEsioTab,
} from "../../../../constants/CareESIO/CareEsioConstants";
import { CareRecipientPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../../Common/Providers/RAFObjectContextProvider";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";

interface IProps {
  onSave?: (values?: any) => void;
  onCancel?: () => void;
  careRecipientRow?: any;
  objectUID?: string;
  mode?: "MedicalHistory" | "Preference";
}

interface IState {
  isLoading: boolean;
  //rafObject: any;
  isEdit: boolean;
}

function UpdateCareRecipient({ careRecipientRow, objectUID, ...props }) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      //rafObject: careRecipientRow,
      isEdit: false,
    }
  );
  let { isEdit } = state;
  let rafForm: FormRenderProps | null;
  let submitButtonRef = useRef(null);
  let moduleName = CareEsioEntity.CareRecipient.EntityName;
  let currentObjectContext = useContext(RAFObjectContext);

  // React.useEffect(() => {
  // }, [rafObject]);

  const onSubmitObject = (
    values,
    entity,
    queryAttributes,
    formModel,
    hasCustomForm
  ) => {
    let updatedValues = R.clone(values);
    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      updatedValues = EvaluateScore(values, queryAttributes);
    }

    if (hasCustomForm) {
      let isFormValid = formModel.validate(true, true);
      if (isFormValid) {
        let progressDiv = showProgress("body", true);
        //set this to full form save
        let result = formModel.data;

        formModel.getAllQuestions().forEach(function (question) {
          if (result[question.name] === undefined) {
            result[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
          }
        });

        updatedValues = { ...updatedValues, ...result };

        SaveDataList(updatedValues, null, moduleName, "DataList/SaveForm")
          .then((response) => {
            hideProgress(progressDiv);
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.entityId)
            ) {
              showSuccessToast(RAFActionMessage.RecordSaved);
              currentObjectContext.setRAFObject(updatedValues);
              setState({ isEdit: false });
            } else {
              showWarningToast(RAFActionMessage.RecordNotSaved);
            }
          })
          .catch((error) => error);
      }
    } else {
      let progressDiv = showProgress("body", true);
      SaveDataList(updatedValues, null, moduleName)
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.entityId)
          ) {
            showSuccessToast(RAFActionMessage.RecordSaved);

            setState({ isEdit: false });
          } else {
            showWarningToast(RAFActionMessage.RecordNotSaved);
          }
        })
        .catch((error) => error);
    }
  };

  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (
        isNotNullAndUndefined(submitButtonRef) &&
        isNotNullAndUndefined(submitButtonRef.current) &&
        isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
      ) {
        submitButtonRef.current.toggleBtnPermission(g.submitting);
      }
    }
  };

  const onToggleManage = () => {
    setState({ isEdit: !isEdit });
  };

  const rightSectionBtn = () => {
    return (
      <div
        className="my-3 d-flex align-items-center justify-content-between"
        id="divHeaderButtons"
      >
        <div className="col-auto">
          <span className="header_5">
            {props.mode === "MedicalHistory"
              ? "Health Details"
              : "Personal Preference"}
          </span>
        </div>
        <RAFPermissionRender
          permissionName={CareRecipientPermissionConstants.CareRecipientManage}
        >
          {isEdit ? (
            <>
              <div className="col-auto ms-auto">
                <RAFButtonComponent
                  key={RAFButtonConstant.Save.Id}
                  className="me-2"
                  type="button"
                  isPrimary
                  action={RAFButtonConstant.Save}
                  onClick={() => rafForm && rafForm.form.submit()}
                  idString="CreateContent"
                  disabled={rafForm && rafForm.submitting}
                  {...(BrowserIsDevice
                    ? { iconBtn: true }
                    : { showIcon: false })}
                />
              </div>
              <div className="col-auto">
                <RAFButtonComponent
                  key={RAFButtonConstant.Cancel.Id}
                  type="button"
                  action={RAFButtonConstant.Cancel}
                  idString="cancelContent"
                  onClick={onToggleManage}
                  {...(BrowserIsDevice
                    ? { iconBtn: true }
                    : { showIcon: false })}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-auto ms-auto">
                <RAFButtonComponent
                  key={RAFButtonConstant.Edit.Id}
                  type="button"
                  className="btn_brand_primary"
                  action={RAFButtonConstant.Edit}
                  onClick={onToggleManage}
                  idString={`${objectUID}__responsive`}
                />
              </div>
            </>
          )}
        </RAFPermissionRender>
      </div>
    );
  };

  const getRecipientContent = () => {
    return (
      <RAFEntityProvider moduleName={moduleName}>
        <RAFEntityContext.Consumer>
          {({ entity, formLibrary }) => {
            let hasCustomForm = false;
            if (
              isNotNullAndUndefined(formLibrary) &&
              isNotNullAndUndefined(formLibrary.FormStructure)
            ) {
              hasCustomForm = true;
            }

            if (
              isNotNullAndUndefined(formLibrary) &&
              isNotNullAndUndefined(formLibrary.FormStructure)
            ) {
              hasCustomForm = true;
            }

            let formStructure;
            if (props.mode === "MedicalHistory") {
              formStructure = {
                title: "Form Title",
                logoPosition: "right",
                pages: [
                  {
                    name: "Background Information",
                    elements: [
                      {
                        type: "panel",
                        name: "panel5",
                        elements: [
                          {
                            type: "comment",
                            name: "allergies_intolerances",
                            title: "Do you have any allergies or intolerances?",
                            description:
                              "List any allergies or intolerances you have to ensure your safety and well-being.",
                            hideNumber: true,
                            allowStorage: true,
                            placeholder: "e.g., Peanuts, lactose intolerance",
                            displayStyle: "Rich Text Editor",
                          },
                          {
                            type: "comment",
                            name: "medical_history",
                            title:
                              "Have you had any recent hospitalizations or surgeries?",
                            description:
                              "Provide details of any recent hospitalizations or surgeries so we can understand your medical history and needs.",
                            hideNumber: true,
                            allowStorage: true,
                            placeholder:
                              "e.g., Hip surgery, hospital stay in March 2023",
                            displayStyle: "Rich Text Editor",
                          },
                        ],
                      },
                    ],
                    title: "Background Information",
                  },
                ],
                showTitle: false,
                showPageTitles: false,
                showCompletedPage: false,
                questionErrorLocation: "bottom",
                clearInvisibleValues: "none",
                completeText: "Save",
              };
            } else if (props.mode === "Preference") {
              formStructure = {
                title: "Form Title",
                logoPosition: "right",
                pages: [
                  {
                    name: "Background Information",
                    elements: [
                      {
                        type: "panel",
                        name: "panel4",
                        elements: [
                          {
                            type: "comment",
                            name: "routines_prefs_impact",
                            title: "What are your important daily routines?",
                            description:
                              "Describe your important daily routines so we can support your preferred structure and activities.",
                            hideNumber: true,
                            allowStorage: true,
                            placeholder:
                              "e.g., Morning walk, specific meal times",
                            displayStyle: "Rich Text Editor",
                          },
                          {
                            type: "comment",
                            name: "preference_likes",
                            title: "What are the things you like?",
                            description:
                              "List things that you enjoy so we can incorporate them into your care and activities.",
                            hideNumber: true,
                            allowStorage: true,
                            placeholder:
                              "e.g., Favorite foods, activities, music",
                            displayStyle: "Rich Text Editor",
                          },
                          {
                            type: "comment",
                            name: "preference_dislike",
                            title: "What are the things you dislike?",
                            description:
                              "List things that you do not enjoy so we can avoid them and make you comfortable.",
                            hideNumber: true,
                            allowStorage: true,
                            placeholder:
                              "e.g., Certain foods, activities, environments",
                            displayStyle: "Rich Text Editor",
                          },
                          {
                            type: "comment",
                            name: "hobbies_interests_impact",
                            title: "What are your hobbies and interests?",
                            description:
                              "Describe your hobbies and interests to help us engage you in meaningful activities.",
                            hideNumber: true,
                            allowStorage: true,
                            placeholder: "e.g., Gardening, reading, sports",
                            displayStyle: "Rich Text Editor",
                          },
                          {
                            type: "comment",
                            name: "dietary_preference",
                            title:
                              "Do you have specific dietary preferences or meal preparation needs?",
                            description:
                              "Describe your dietary preferences and any specific needs for meal preparation.",
                            hideNumber: true,
                            allowStorage: true,
                            displayStyle: "Rich Text Editor",
                          },
                        ],
                      },
                    ],
                    title: "Background Information",
                  },
                ],
                showTitle: false,
                showPageTitles: false,
                showCompletedPage: false,
                questionErrorLocation: "bottom",
                clearInvisibleValues: "none",
                completeText: "Save",
              };
            } else {
              formStructure = formLibrary?.FormStructure;
            }

            let formModel = new Model(formStructure);

            formModel.applyTheme(customTheme as ITheme);
            let nextButton =
              formModel.navigationBar.getActionById("sv-nav-next");
            let prevButton =
              formModel.navigationBar.getActionById("sv-nav-prev");
            let completeButton =
              formModel.navigationBar.getActionById("sv-nav-complete");
            nextButton.visible = false;
            prevButton.visible = false;
            completeButton.visible = false;

            formModel.onErrorCustomText.add(function (sender, options) {
              if (options.name == "required") {
                //options.text = options.obj.title + " is required";
                options.text = "This field is required";
              }
            });

            formModel.mode = isEdit ? "edit" : "display";

            return (
              <RAFAttributeRelatedListProvider moduleName={moduleName}>
                <RAFAttributesContext.Consumer>
                  {({ queryAttributes }) => {
                    return (
                      <RAFObjectContextProvider
                        moduleName={moduleName}
                        objectId={objectUID}
                        progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                      >
                        <RAFObjectContext.Consumer>
                          {({ /*objectId, */ rafObject, error }) => {
                            if (
                              isNotNullAndUndefined(rafObject) &&
                              !IsNullOrWhiteSpace(rafObject.UID)
                            ) {
                              formModel.data = rafObject;
                              return (
                                <RAFForm
                                  initialValues={rafObject}
                                  formRef={(g) => {
                                    enableSubmitButton(g);
                                    return (rafForm = g);
                                  }}
                                  layout={RAFLayout.TwoColumnLayout}
                                  onSubmit={(values) =>
                                    onSubmitObject(
                                      values,
                                      entity,
                                      queryAttributes,
                                      formModel,
                                      hasCustomForm
                                    )
                                  }
                                  className="h-100"
                                  //decorators={[accountDecorators]}
                                  //convertBeforeSave={ConvertAccountRowToAccountDto}
                                >
                                  <div className="e-dlg-body-content h-100">
                                    {hasCustomForm && (
                                      <>
                                        <Survey model={formModel} />
                                      </>
                                    )}
                                  </div>
                                  <div className="e-dlg-footerContent "></div>
                                </RAFForm>
                              );
                            } else {
                              if (error === Constants.RAFObject_NoContent) {
                                return (
                                  <div className="container-fluid px-0">
                                    <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    className="container-fluid px-0"
                                    style={{
                                      background: "transparent",
                                    }}
                                  >
                                    {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                  </div>
                                );
                              }
                            }
                          }}
                        </RAFObjectContext.Consumer>
                      </RAFObjectContextProvider>
                    );
                  }}
                </RAFAttributesContext.Consumer>
              </RAFAttributeRelatedListProvider>
            );
          }}
        </RAFEntityContext.Consumer>
      </RAFEntityProvider>
    );
  };

  if (isNotNullAndUndefined(objectUID) && !IsNullOrWhiteSpace(objectUID)) {
    return (
      <div key={`key${isEdit}`}>
        <div className="section__container">
          {rightSectionBtn()}
          <div
            className="section__div section__divY"
            style={BrowserWidth > 767  ? { margin: "0 -1rem" } : { margin: "0 -0.5rem" }}
          >
            {getRecipientContent()}
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default React.memo(UpdateCareRecipient);
