import { ContentType } from "../../../constants/Common/Constants";
import { getRelatedRecords } from "../../../RAFComponents/helpers/AppHelper";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
} from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";

export class CareRecipientSummary {
  Risk: number;
  Trigger: number;
  Preference: number;
  Medication: number;
  HealthCondition: number;
}

export const getActiveCarePlanByCareRecipient = (
  careRecipientUID: string,
  relatedEntityUID: string
) => {
  return new Promise<{}>((resolve, reject) => {
    if (isNotNullAndUndefined(relatedEntityUID)) {
      getRelatedRecords(
        null,
        null,
        "CareRecipientUID",
        careRecipientUID,
        relatedEntityUID
      )
        .then((carePlanresponse) => {
          if (isNotEmptyArray(carePlanresponse)) {
            const activeCarePlan = carePlanresponse[0];
            if (
              isNotNullAndUndefined(activeCarePlan) &&
              !IsNullOrWhiteSpace(activeCarePlan["UID"])
            ) {
              repositoryActions
                .postDataAndGetResponse(
                  "DataList/Retrieve",
                  {
                    EntityId: activeCarePlan["UID"],
                    EntityUID: relatedEntityUID,
                  },
                  null,
                  ContentType.applicationJson
                )
                .then((response) => {
                  if (response.status === 204) {
                    resolve(null);
                  } else {
                    if (
                      response.data &&
                      response.data.ResultTable &&
                      response.data.ResultTable.length > 0
                    ) {
                      resolve(response.data.ResultTable[0]);
                    } else {
                      resolve(null);
                    }
                  }
                })
                .catch((error) => error);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getCareRecipientHealthSummary = (careRecipientUID: string) => {
  return new Promise<CareRecipientSummary>((resolve) => {
    if (isNotNullAndUndefined(careRecipientUID)) {
      return repositoryActions
        .postDataAndGetResponse(
          "DataList/CareRecipientHealthSummary",
          {
            EntityId: careRecipientUID,
          },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            let careRecipientSummary = new CareRecipientSummary();
            careRecipientSummary.Risk = response.data.Entity.Risk;
            careRecipientSummary.Trigger = response.data.Entity.Trigger;
            careRecipientSummary.Medication = response.data.Entity.Medication;
            careRecipientSummary.Preference = response.data.Entity.Preference;
            careRecipientSummary.HealthCondition =
              response.data.Entity.HealthCondition;
            resolve(careRecipientSummary);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};
