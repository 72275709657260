import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import { getRelatedRecords } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  ConvertSystemName,
  isNotEmptyArray,
  isNotNullAndUndefined,
  joinStringArray,
} from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareRecipientPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFButtonConstant,
} from "../../../constants/Common/Constants";
import ServiceTransactionTemplateList from "../../ActiveContacts/ServiceTransaction/ServiceTransactionTemplate/ServiceTransactionTemplateList";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import CareServiceContractCardContent from "./CareServiceContractCardContent";
import ManageCareServiceContract from "./ManageCareServiceContract";

import "./care_service_contract_style.scss"

interface IProps {
  careRecipientUID: string;
  careRecipientName: string;
}

interface IState {
  isLoading: boolean;
  allServiceContractList: any[];
  careServiceContractEntity: EntityRow;
  careServiceContractItemEntity: EntityRow;
  showCreateCareServiceContract: boolean;
}

function CareServiceContractList({ ...props }: PropsWithChildren<IProps>) {
  const objectContext = React.useContext(RAFObjectContext);
  const rafObject = isNotNullAndUndefined(objectContext)
    ? objectContext.rafObject
    : null;

  const careServiceContractModule =
    CareEsioEntity.CareServiceContract.EntityName;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: false,
      allServiceContractList: null,
      careServiceContractEntity: null,
      careServiceContractItemEntity: null,
      showCreateCareServiceContract: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.careRecipientUID]);

  const refresh = async () => {
    setState({ isLoading: true, showCreateCareServiceContract: false });
    const careServiceContractEntity = await getEntityByObjectName({
      ObjectName: ConvertSystemName(careServiceContractModule),
    });
    const careServiceContractItemEntity = await getEntityByObjectName({
      ObjectName: ConvertSystemName(
        CareEsioEntity.CareServiceContractItem.EntityName
      ),
    });

    let sortBy = {
      field: "StartDate",
      order: "descending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sortBy) && isNotNullAndUndefined(sortBy.field)) {
      sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
      );
    }

    const allServiceContractList = await getRelatedRecords(
      careServiceContractModule,
      null,
      "CareRecipientUID",
      props.careRecipientUID,
      careServiceContractEntity.UID,
      null,
      sortQuery
    );

    setState({
      isLoading: false,
      allServiceContractList,
      careServiceContractEntity,
      careServiceContractItemEntity,
    });
  };

  const getServiceTransactionTemplateList = () => {
    return (
      <ServiceTransactionTemplateList
        relatedToUID={props.careRecipientUID}
        relatedToType={CareEsioEntity.CareRecipient.EntityName}
        relatedTo={props.careRecipientName}
        transactionTemplateCardUIMode="Card"
        showEmptyMessage={false}
      />
    );
  };

  const serviceContractListContent = () => {
    return (
      <RAFPermissionRender
        permissionName={
          CareRecipientPermissionConstants.CareRecipientViewServiceAgreements
        }
        showDefaultNoPermissionContent
      >
          <div className="row g-3" id="service_contract_list_content">
            <div className="col-12">
        <div className="d-flex align-items-center justify-content-between p-2 p-md-3">
                <div>
                  <div className="header_5">
                    <span>
                      {CareEsioEntity.CareServiceContract.CollectionName}
                    </span>
                  </div>
                  <div className="body_2">
                    <span>
                      All service agreements and associated support items.
                    </span>
                  </div>
                </div>
                <RAFPermissionRender
                  permissionName={
                    CareRecipientPermissionConstants.CareRecipientManageServiceAgreements
                  }
                >
                  <div>
                    <RAFButtonComponent
                      isPrimary
                      btnContent={`Add ${"Agreement"}`}
                      // btnContent={`Add ${CareEsioEntity.CareServiceContract.DisplayName}`}
                      action={RAFButtonConstant.Add}
                      onClick={() => onClickAddCareServiceContract()}
                    ></RAFButtonComponent>
                  </div>
                  {createServiceContractDlgDiv()}
                </RAFPermissionRender>
              </div>
              <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            {careServiceContractListItem()}
            <div className="col-12">{getServiceTransactionTemplateList()}</div>
            {false && (
              <RAFPermissionRender
                permissionName={
                  CareRecipientPermissionConstants.CareRecipientManageServiceAgreements
                }
              >
                {state.isLoading === false && (
                  <div className="col-12 d-flex justify-content-center">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Add}
                      onClick={() => onClickAddCareServiceContract()}
                      isPrimary
                      {...(!BrowserIsDevice
                        ? { uiMode: "FooterBtn" }
                        : { displayMode: "FabComponent" })}
                    ></RAFButtonComponent>
                    {/* <FabComponent
                    id={`btn_${RAFButtonConstant.Add.Id}_${CareEsioEntity.CareServiceContract.EntityName}`}
                    iconCss={RAFButtonConstant.Add.IconCss} content={RAFButtonConstant.Add.DisplayName}
                    onClick={() => onClickAddCareServiceContract()}
                  // target={`#care_${CareEsioEntity.CareServiceContract.EntityName}_div`}
                  ></FabComponent> */}
                    {/* <RAFButtonComponentS
                    isPrimary
                    // className="custom-button-md"
                    action={RAFButtonConstant.Add}
                    btnContent={`Add ${CareEsioEntity.CareServiceContract.DisplayName}`}
                    onClick={() => onClickAddCareServiceContract()}
                    uiMode="FooterBtn"
                  /> */}
                  </div>
                )}
                {createServiceContractDlgDiv()}
              </RAFPermissionRender>
            )}
          </div>
      </RAFPermissionRender>
    );
  };

  const careServiceContractListItem = () => {
    if (state.isLoading === false) {
      if (isNotEmptyArray(state.allServiceContractList)) {
        return (
          <div
            className="col-12"
            id={`care_${CareEsioEntity.CareServiceContract.EntityName}_div`}
          >
            <div className="row g-3">
              {state.allServiceContractList.map((item) => {
                return (
                  <div className="col-12 care_service_contract_col" key={item.UID}>
                      <CareServiceContractCardContent
                        key={item.UID}
                        careRecipientUID={props.careRecipientUID}
                        careServiceContractRow={item}
                        careServiceContractEntity={
                          state.careServiceContractEntity
                        }
                        careServiceContractItemEntity={
                          state.careServiceContractItemEntity
                        }
                        onSave={refresh}
                      />
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        return (
          <div className="col-12">
            <RAFEmptyState
              title={`You don't have any ${CareEsioEntity.CareServiceContract.CollectionName} yet.`}
              body={`No service contracts available at this time. Click on add button to create a new ${CareEsioEntity.CareServiceContract.CollectionName}.`}
            />
          </div>
        );
      }
    } else {
      return (
        <div className="col-12">
          <div className="container-fluid px-0">
            <ACLoadingPanel loadingText="Preparing Data..." />
          </div>
        </div>
      );
    }
  };

  //add serviceContract content start

  const onClickAddCareServiceContract = () => {
    setState({ showCreateCareServiceContract: true });
  };

  const createServiceContractDlgDiv = () => {
    return (
      state.showCreateCareServiceContract && (
        <DialogComponent
          header={`Add ${CareEsioEntity.CareServiceContract.DisplayName}`}
          showCloseIcon
          visible={state.showCreateCareServiceContract}
          cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          id={`dlg_create_${careServiceContractModule}`}
          content={createCareServiceContractContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeCreateCareServiceContractDialog.bind(this)}
          open={PreventFocusOnDialogOpen}
          zIndex={1200}
        ></DialogComponent>
      )
    );
  };

  const createCareServiceContractContent = () => {
    const careRecipientName = isNotNullAndUndefined(rafObject)
      ? isNotNullAndUndefined(rafObject.recipient_name)
        ? rafObject.recipient_name
        : joinStringArray([
          rafObject.first_name,
          rafObject.middle_name,
          rafObject.last_name,
        ])
      : "";
    if (state.showCreateCareServiceContract) {
      let initialValues = {};
      if (isNotNullAndUndefined(rafObject)) {
        initialValues["CareRecipientUID"] = rafObject.UID;
        initialValues["CareRecipient"] = careRecipientName;
        initialValues["IsActive"] = true;
      }

      return (
        <ManageCareServiceContract
          initialValues={initialValues}
          onSave={onCreateCareServiceContractList.bind(this)}
          onClose={closeCreateCareServiceContractDialog.bind(this)}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const onCreateCareServiceContractList = () => {
    refresh();
  };

  const closeCreateCareServiceContractDialog = () => {
    setState({ showCreateCareServiceContract: false });
  };
  //add serviceContract content end

  if (isNotNullAndUndefined(props.careRecipientUID)) {
    return serviceContractListContent();
  } else {
    return <div></div>;
  }
}

export default React.memo(CareServiceContractList);
