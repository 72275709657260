import React, { PropsWithChildren } from "react";
import { getRecordCount } from "../../../../RAFComponents/helpers/AppHelper";
import {
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
} from "../../../../RAFComponents/helpers/utils";

interface IProps {
  relatedField?: string;
  relatedFieldValue?: string;
  entityUID?: string;
}

export default function RAFRecordCount({
  // children,
  relatedField,
  relatedFieldValue,
  entityUID,
  ...props
}: PropsWithChildren<IProps>) {
  const [recordCount, setRecordCount] = React.useState(0);

  React.useEffect(() => {
    if (IsNullOrWhiteSpace(entityUID)) {
      return;
    }
    getRecordCount(null, null, relatedField, relatedFieldValue, entityUID).then(
      (recordCount) => {
        setRecordCount(recordCount);
      }
    );
  }, [entityUID]);

  if (recordCount > 0) {
    return (
      <span className={"ms-2 e-badge e-badge-primary e-badge-circle"}>
        {recordCount}
      </span>
    );
  } else {
    return <></>;
  }
}
